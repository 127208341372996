import React from "react"

function Receipt(props) {
  const {
    accountName,
    accountPhone,
    accountEmail,
    accountStreet,
    accountCity,
    accountState,
    accountZip,
    accountImage,
    firstName,
    lastName,
    address,
    city,
    state,
    zip,
    orderId,
    amountPaid: totalAmount,
    // process,
    // paymentType,
    last4,
    // cardType,
    authCode,
    avsResponse,
    // invoiceUrl,
    termsAndConditions,
    capture_signature,
    surchargeFee,
    surchargeMessage,
    hideHeader = false,
    synchronyPromoCode,
    synchronyPromoDescr, 
  } = props
  
  let {
    cardType,
    paymentType
  } = props

  cardType = cardType.charAt(0).toUpperCase() + cardType.slice(1)
  paymentType = paymentType.charAt(0).toUpperCase() + paymentType.slice(1)

  let {
    payWithACH, 
  } = props
  if (payWithACH === undefined || payWithACH === null) payWithACH = false
  console.log('in receipt', payWithACH, surchargeFee, surchargeMessage,synchronyPromoCode,synchronyPromoDescr)
  let subtotal = surchargeFee ? totalAmount - surchargeFee : totalAmount
  console.log('in receipt' + JSON.stringify(props))
  return (
    <div className="container-fluid">
      {!hideHeader && accountImage && (
        <div className="row justify-content-center">
          <div className="col-5 mt-2 mh-100">
            <img src={accountImage} alt="image" className="rounded img-fluid border"/>
          </div>
        </div>
      )}
      {!hideHeader && (
       <div>
         <h3>{accountName}</h3>
         <h5>{accountStreet}</h5>
         <h5>{accountCity}, {accountState} {accountZip}</h5>
         <h5>{accountPhone}</h5>
         <h5>{accountEmail}</h5>
       </div> 
      )}
      <div className="col justify-content-center">
        <div className="card bg-light mt-2">
          <div className="card-header">
            Receipt of Payment
          </div>
          <div className="card-body">
            <div className="container text-start">
              <div className="row">
                <div className="col-6">
                  <div className="row">
                    <div className="col-12">
                      <b>Name</b>
                    </div> 
                  </div>
                  <div className="row">
                    <div className="col-12">
                    {firstName} {lastName}
                    </div> 
                  </div>
                  {!hideHeader && (
                    <div className="row">
                      <div className="col-12">
                        <b>Address</b>
                      </div> 
                    </div>
                  )}
                  {!hideHeader && (
                    <div className="row">
                      <div className="col-12">
                        <div className="row">
                          <div className="col-12">
                          {address}
                          </div> 
                        </div>
                        <div className="row">
                          <div className="col-12">
                            {city} {state}, {zip}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="row">
                    <div className="col-12">
                      <b>Card Type</b>
                    </div> 
                  </div>
                  <div className="row">
                    <div className="col-12">
                    { payWithACH ?  (
                      <div>ach</div>
                    ) : <div>{ cardType }</div> }
                    </div> 
                  </div>

                  { synchronyPromoCode && (
                    <div>
                    <div className="row">
                      <div className="col-12">
                        <b>Synchrony Promo</b>
                      </div> 
                    </div>
                    <div className="row">
                      <div className="col-12">
                        <div>{ synchronyPromoCode } - { synchronyPromoDescr }</div>
                      </div> 
                    </div>
                  </div>
                  )}

                  <div className="row">
                    <div className="col-12">
                    { payWithACH ?  (
                      <b></b>
                    ) : <b>Card #</b> }
                    </div> 
                  </div>
                  <div className="row">
                    <div className="col-12">
                      {last4}
                    </div> 
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <b>Type</b>
                    </div> 
                  </div>
                  <div className="row">
                    <div className="col-12">
                      {paymentType}
                    </div> 
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <b>Approval Code</b>
                    </div> 
                  </div>
                  <div className="row">
                    <div className="col-12">
                      {authCode}
                    </div> 
                  </div>
                </div>
                <div className="col-6">
                  <div className="row">
                    <div className="col-12">
                    <b>Invoice</b>
                    </div> 
                  </div>
                  <div className="row">
                    <div className="col-12">
                      {/* {invoiceUrl ? (<a href={invoiceUrl}>Link</a>) : '' } */}
                      {orderId}
                    </div> 
                  </div>
                  { surchargeFee && (
                    <div className="row">
                      <div className="col-12">
                        <b>Amount</b>
                      </div> 
                    </div>
                  )}
                  { surchargeFee && (
                    <div className="row">
                      <div className="col-12">
                        {new Intl.NumberFormat('en-US',
                          { style: 'currency', currency: 'USD',
                            minimumFractionDigits: 2 }).format(subtotal)}
                      </div> 
                    </div>
                  )}
                  { surchargeFee && (
                    <div className="row">
                      <div className="col-12">
                        <b>Fee*</b>
                      </div> 
                    </div>
                  )}
                  { surchargeFee && (
                    <div className="row">
                      <div className="col-12">
                        {new Intl.NumberFormat('en-US',
                          { style: 'currency', currency: 'USD',
                            minimumFractionDigits: 2 }).format(surchargeFee)}
                      </div> 
                    </div>
                  )}
                  <div className="row">
                    <div className="col-12">
                      <b>Total Amount</b>
                    </div> 
                  </div>
                  <div className="row">
                    <div className="col-12">
                      {new Intl.NumberFormat('en-US',
                        { style: 'currency', currency: 'USD',
                          minimumFractionDigits: 2 }).format(totalAmount)}
                    </div> 
                  </div>
                  
                  
                  <div className="row">
                    <div className="col-12">
                      <b>AVS Response</b>
                    </div> 
                  </div>
                  <div className="row">
                    <div className="col-12">
                      {avsResponse}
                    </div> 
                  </div>
                  
                </div>
              </div>
            
              <div className="row">
                <div className="col-6 mt-1">
                  {termsAndConditions ? (<p >{termsAndConditions}</p>) : ''}
                </div>
              </div>
              <div className="row">
                <div className="col-6 mt-1">
                  {capture_signature ? ( <img src={capture_signature} alt="image" className="img-fluid m-1"/>) : ''}
                </div>
              </div>
              { surchargeFee && (
                <div className="row">
                  <div className="col-12 mt-1">
                    <b>*</b>{surchargeMessage}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Receipt


