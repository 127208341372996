import React, { useState } from 'react'
import { PayPalButtons, PayPalScriptProvider } from "@paypal/react-paypal-js"
import { getFirebaseConfig } from '../lib'
import { Buffer } from 'buffer'

const {
    paypalclientid,
    paypalsecret,
    paypalbncode,
    paypalurl,
    paypalauthurl,
    partnermerchant,
  } = getFirebaseConfig()

const authStr = paypalclientid+':'+paypalsecret
const token = gettoken(authStr)
let authtoken
function gettoken (str)  {
    let bd = `${Buffer.from(str).toString('base64')}`
    return bd
}

function PayPal ({ props, finishPayPal, payPalId, platformfee, service, hidePayLater } )  {
    let { amount, hasVenmo, hasPayPal, refId } = props
    const txnId = refId
    const description = "Payment for Transaction " + txnId
    const merchantid =payPalId
    const [ authCode, setAuthCode] = useState('')
    let debug_id = ''
    let paymentSource = ''
    let paypalerror = ''
    platformfee = platformfee*amount
    if (platformfee<.01) platformfee = .01
    if (platformfee) platformfee = platformfee.toFixed(2)
    
    console.log('paypal hidePayLater: ', hidePayLater)
    console.log('hasVenmo: ' + hasVenmo)
    console.log('hasPayPal: ' + hasPayPal)
    
    let initialOptions
    if (hidePayLater) {
        initialOptions = {
            "client-id": paypalclientid,
            "commit":"true",
            currency: "USD",
            intent: "capture",
            //"enable-funding":(hasVenmo?"venmo":""),
            "enable-funding":"paylater,venmo",
            "disable-funding": "card,credit",
            "merchant-id": merchantid,
            "components":"buttons,funding-eligibility",
            // "buyer-country":"US", // *** comment out for Prod
        }
    } else {
        initialOptions = {
            "client-id": paypalclientid,
            "commit":"true",
            currency: "USD",
            intent: "capture",
            //"enable-funding": "paylater"+(hasVenmo?",venmo":""),
            "enable-funding": "paylater,venmo",
            "disable-funding": "card",
            "merchant-id": merchantid,
            "components":"buttons,funding-eligibility",
            // "buyer-country":"US", // *** comment out for Prod
        }
    }

    function createOrder(data) {
        //console.log('createOrder: ' + JSON.stringify(actions))
                        //console.log('createOrder: ' + JSON.stringify(data))
                        //console.log('token: ' + JSON.stringify(token))
                        paymentSource = data.paymentSource
                        if (paymentSource === 'paylater') paymentSource = 'paypal'

                        return fetch(paypalauthurl + '/v1/oauth2/token?grant_type=client_credentials', { method:'POST', 
                        headers: { 'Authorization': 'Basic ' + token
                        }
                        }).then(function(res) {
                            //console.log('auth response: ' + JSON.stringify(res))
                            return res.json()
                        }).then(function(authData) {
                            console.log('new')
                            authtoken = authData.access_token
                            
                            let body = '{"intent": "CAPTURE",' + 
                            '"payment_source": { "' + paymentSource + '": { "experience_context": { "brand_name": "360 Payments Page", "shipping_preference": "GET_FROM_FILE", "landing_page": "LOGIN", "user_action": "PAY_NOW", "payment_method_preference": "IMMEDIATE_PAYMENT_REQUIRED", "locale": "en-US", "payment_method_selected": "PAYPAL" } } }, ' + 
                            '"purchase_units": [{' + 
                            '"items": [{"name": "' + service + '","quantity": "1","description": "' + service + '","category": "PHYSICAL_GOODS","unit_amount": {"currency_code": "USD","value": "' + amount + '"}}],' + 
                            '"payee": {"merchant_id": "'+merchantid+'"}, "payment_instruction": { "platform_fees": [{ "amount": { "currency_code": "USD", "value": "' + platformfee + '" }, "payee": { "merchant_id": "' + partnermerchant + '"}}]}, ' + 
                            '"shipping": { "type": "SHIPPING"}, ' + 
                            '"description": "' + description + '","invoice_id": "' + txnId + '","amount": {"currency_code": "USD","value": "'+ amount +'", ' + 
                            '"breakdown": {"item_total": {"currency_code": "USD","value": "' + amount + '"}}' + 
                            '}}]}'
                            //console.log('body', body)
                            return fetch(paypalurl + '/v2/checkout/orders', {
                                headers: {
                                Authorization: "Bearer " + authtoken,
                                "Content-Type":"application/json","data-partner-attribution-id": paypalbncode,"PayPal-Partner-Attribution-Id": paypalbncode ,"PayPal-Request-Id": txnId },
                                body:body,
                                method: 'post'
                            }).then(function(res) {
                                try {
                                    debug_id = ( res.headers.get('Paypal-Debug-Id'))
                                    console.log('checkout orderData debug_id ', debug_id)
                                } catch (errdebug) {console.log('could not get header')}
                                
                                return res.json()
                            }).then(function(orderData) {
                                //console.log('checkout orderData: ' + JSON.stringify(orderData))
                                if (orderData.debug_id) debug_id = orderData.debug_id
                                console.log('debug_id: ' + debug_id)
                                if (orderData.message) paypalerror = orderData.message
                                var errorDetail = Array.isArray(orderData.details) && orderData.details[0]
                                if (errorDetail) paypalerror += errorDetail.description
                                console.log('paypalerror',paypalerror)
                                return orderData.id
                            }, (err) => {
                                console.log('err /v2/checkout/orders : ' + JSON.stringify(err))
                                finishPayPal('Error: ' + JSON.stringify(err),paymentSource, debug_id, paypalurl + '/v2/checkout/orders','',body, JSON.stringify(err) )
                                return
                            })
                        }, (err) => {
                            console.log('throwing error', debug_id)
                            finishPayPal('Error: ' + JSON.stringify(err),paymentSource, debug_id, paypalurl + '/v2/checkout/orders','', 'Unknown', JSON.stringify(err))
                            return
                        })
    }

    function approve(data, actions) {
        let debug_id = ''
        paymentSource = data.paymentSource
        let url = paypalurl + '/v2/checkout/orders/' + data.orderID + '/capture/'
        //console.log('finalize paymentSource: ' + paymentSource)
        return fetch(url, {
            headers: {
            //Authorization: "Bearer "+data.facilitatorAccessToken,
            Authorization: "Bearer "+authtoken,
            "Content-Type":"application/json","data-partner-attribution-id": paypalbncode, "PayPal-Partner-Attribution-Id": paypalbncode, },method: 'post'
        }).then(function(res) {
            try {
                //console.log('paypal capture res', JSON.stringify(res))
                debug_id = ( res.headers.get('Paypal-Debug-Id'))
                console.log('capture orderData debug_id', debug_id)
            } catch (errdebug) {console.log('could not get header')}
            return res.json()
        }).then(function(orderData) {
            //console.log('capture orderData: ' + JSON.stringify(orderData) + ' ' + paymentSource)
            var errorDetail = Array.isArray(orderData.details) && orderData.details[0]

            if (errorDetail && errorDetail.issue === 'INSTRUMENT_DECLINED') {
                return actions.restart()
            }
            //console.log('errorDetail',errorDetail)
            if (errorDetail) {
                var msg = 'Sorry, your transaction could not be processed.'
                if (errorDetail.description) msg += '\n\n' + errorDetail.description
                if (orderData.debug_id) msg += ' (' + orderData.debug_id + ')'
                finishPayPal('Error: ' + msg,paymentSource, orderData.debug_id, paypalurl + '/v2/checkout/orders' + data.orderID + '/capture/','', url, JSON.stringify(orderData))
                return
            }
            //console.log('getting authCode')
            if (orderData.purchase_units && orderData.purchase_units[0] && orderData.purchase_units[0].payments) {
                var transaction = orderData.purchase_units[0].payments.captures[0]
                //console.log('transaction',JSON.stringify(transaction))
                setAuthCode(transaction.id)
                console.log('authCode',authCode)
                finishPayPal(transaction.id, paymentSource, '', '', orderData.payer.email_address, url, JSON.stringify(orderData))
            }
            return
            
        }, (err) => {
            finishPayPal('Error: ' + JSON.stringify(err),paymentSource, debug_id, paypalurl + '/v2/checkout/orders' + data.orderID + '/capture/', '', url, JSON.stringify(err))
        })
    }

    function error(error) {
        console.log('onError', debug_id, JSON.stringify(error), paypalerror)
        finishPayPal('Error: ' + paypalerror,paymentSource, debug_id, paypalurl + '/v2/checkout/orders','')
        return
    }

    // console.log('initialOptions: ' + JSON.stringify(initialOptions))
    
    return (

            
            <div>
               
                <PayPalScriptProvider options={initialOptions}>
                    
                
                { hasPayPal ? <div><PayPalButtons 
                    fundingSource="paypal" createOrder={createOrder} onError={error} onApprove={approve} /></div> : <div></div> }

                { hasVenmo ? <div><PayPalButtons 
                    fundingSource="venmo" createOrder={createOrder} onError={error} onApprove={approve} /> </div> : <div></div> }
                
                { hasPayPal && !hidePayLater ? <div><PayPalButtons 
                    fundingSource="paylater" createOrder={createOrder} onError={error} onApprove={approve} /></div> : <div></div> }
                    
                </PayPalScriptProvider>

            </div>

    )

}

export default PayPal