import React, { useState } from 'react'
import { initializeApp } from 'firebase/app'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { getFirebaseConfig } from '../lib'

import './payment.css'
import SignaturePad from 'react-signature-pad-wrapper'
import ReCAPTCHA from "react-google-recaptcha"
import html2canvas from 'html2canvas'

//synch d-buy
import SynchDbuy from './synchdbuy2.js'

// import banner from '../images/banner.png'

import Receipt from './receipt.js'

const recaptchaRef = React.createRef()

const app = initializeApp(getFirebaseConfig())

// const analytics = getAnalytics(app) // eslint-disable-line no-unused-vars
export default class Payment extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      paymentMethodsAch: {
        creditCard: {
          enabled: false
        },
        ach: {
          enabled: true
        }
      },
      paymentMethodsCC: {
        creditCard: {
          enabled: true
        },
        ach: {
          enabled: false
        }
      },
      fields: {
        ccnumber: {
          valid: false
        },
        ccexp: {
          valid: false
        },
        cvv: {
          valid: false
        },
        checkaba: {
          valid: false
        },
        checkaccount: {
          valid: false
        },
        checkname: {
          valid: false
        }
      },
      firstName: '',
      lastName: '',
      phone: '',
      address: '',
      city: '',
      state: '',
      zip: '',
      amount: undefined,
      capture_signature: undefined,
      isSubmitting: false,
      notInvalid: false,
      submitDisabled: true,
      alertMessage: '',
      merchantId: undefined,
      orderId: undefined,
      accountName: '',
      accountPhone: '',
      accountEmail: '',
      accountStreet: '',
      accountCity: '',
      accountState: '',
      accountZip: '',
      accountImage: '',
      receipt: false,
      complete: false,
      lendResult: '',
      lendResultLoading: true,
      isSynchrony: false,
      lendApplyUrl: '',
      signatureHidden: true,
      showCaptchaSignature: false,
      Venmo: '',
      hasApplePay: false,
      hasGooglePay: false,
      hasVenmo: false,
      hasPayPal: false,
      payPalMerchantStatus: '',
      hasCFP: false,
      Hide_Paypal_Pay_Later: false,
      disclosure: '',
      disableCC: false,
      worldnetUrl: undefined,
      isInternal: false,
      isExpired: false,
      isComplete: false,
      isCanceled: false,
      invalid: false,
      invalidMessage: '',
      payWithACH: false,
      internalNotes: '',
      showOrderId: false,
      hideHeader: false,
      hideAuxillaryPayments: false,
      dBuyStarted: false,
      dBuyNeedsFinal: false,
      dBuyClosed: false,
      synchronyPromoCode: '', 
      synchronyPromoDescr: '',
      synchkey: 1,
    }
    this.setState = this.setState.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.finishSubmit = this.finishSubmit.bind(this)
    this.loadCollect = this.loadCollect.bind(this)
    this.loadACHCollect = this.loadACHCollect.bind(this)
    this.handleEmail = this.handleEmail.bind(this)
    this.onChange = this.onChange.bind(this)
    this.onPhoneChange = this.onPhoneChange.bind(this)
    this.handleClear = this.handleClear.bind(this)
    this.toggleSignature = this.toggleSignature.bind(this)
    this.toggleACH = this.toggleACH.bind(this)

    this.handleGetSignature = this.handleGetSignature.bind(this)
    this.fieldValidation = this.fieldValidation.bind(this)
    this.submitError = this.submitError.bind(this)
    this.updateSubmitDisabled = this.updateSubmitDisabled.bind(this)
    this.fieldsAvailable = this.fieldsAvailable.bind(this)
    this.handleCancelAlert = this.handleCancelAlert.bind(this)
    // this.loadLendResults = this.loadLendResults.bind(this)
    this.signaturePadRef = React.createRef()
    //this.getImage('Venmo')
  }

  /*getImage (image) {
    storage.child(`${image}.png`).getDownloadURL().then((url) => {
      this.state[image] = url
      this.setState(this.state)
    })
  }
  */
  async componentDidMount() {
    const functions = getFunctions(app)
    const signedPayment = httpsCallable(functions, 'onCall-signedPayment')
    try {
      const urlSearchParams = new URLSearchParams(window.location.search)
      const {
        id,
      } = Object.fromEntries(urlSearchParams.entries())
      
      console.log('calling signedPayment')
      const result = await signedPayment({ id })
      console.log('signedPayment', JSON.stringify(result))
      const {
        accountName,
        accountPhone,
        accountEmail,
        accountStreet,
        accountCity,
        accountState,
        accountZip,
        accountImage,
        firstName,
        lastName,
        phone,
        address,
        city,
        state,
        zip,
        orderId,
        merchantId,
        terminalId,
        amount,
        datetime,
        expire,
        refId,
        signature,
        cfpPartnerKey,
        termsAndConditions,
        invoiceUrl,
        invoiceRef,
        payPalId,
        payPalMerchantStatus = '',
        payPalPlatformFee,
        disclosure = '',
        payPalService = '',
        hasApplePay = false,
        hasGooglePay = false,
        hasVenmo = false,
        hasPayPal = false,
        hasCFP = false,
        disableCC = false,
        hasCreditCard = false,
        velox_token_key,
        worldnetUrl,
        isInternal = false,
        hideHeader = false,
        achEnabled = false,
        authOnly = false,
        hideAuxillaryPayments = false,
        internalNotes,
        showOrderId,
        Hide_Paypal_Pay_Later = false,
      } = result.data
      this.setState({
        merchantId,
        terminalId,
        amount,
        datetime, 
        expire, 
        refId,
        signature,
        accountName,
        accountPhone,
        accountEmail,
        accountStreet,
        accountCity,
        accountState,
        accountZip,
        accountImage,
        firstName,
        lastName,
        phone,
        address,
        city,
        state,
        zip,
        orderId,
        cfpPartnerKey,
        termsAndConditions,
        invoiceUrl,
        invoiceRef,
        payPalId,
        payPalMerchantStatus,
        payPalPlatformFee,
        disclosure,
        payPalService,
        hasApplePay,
        hasGooglePay,
        hasVenmo,
        hasPayPal,
        hasCFP,
        Hide_Paypal_Pay_Later,
        disableCC,
        hasCreditCard,
        worldnetUrl,
        palPalComplete:false,
        paymentSource:'',
        payer_email:'',
        isInternal,
        hideAuxillaryPayments,
        hideHeader,
        achEnabled,
        payWithACH:false,
        velox_token_key:velox_token_key,
        authOnly,
        internalNotes,
        showOrderId,
      })
      // console.log('result.data', JSON.stringify(result.data))
      // console.log('componentDidMount achEnabled', achEnabled)
      // console.log('hasPayPal', this.state.hasPayPal)
      // console.log('authOnly', this.state.authOnly)
      // console.log('hasVenmo', this.state.hasVenmo)
      // console.log('payWithACH', this.state.payWithACH)
      // console.log('payPalService', this.state.payPalService)
      // console.log('payPalMerchantStatus', this.state.payPalMerchantStatus)
      // console.log('payment hasCFP', this.state.hasCFP)
      // console.log('Hide_Paypal_Pay_Later', this.state.Hide_Paypal_Pay_Later)
      // console.log('hideAuxillaryPayments', this.state.hideAuxillaryPayments)
      // console.log('hasApplePay', this.state.hasApplePay)
      if (velox_token_key) {
        // console.log('Key ', velox_token_key)
        const script = document.createElement("script")
        script.src = "https://velox.transactiongateway.com/token/Collect.js"
        script.async = false
        script.setAttribute('data-tokenization-key', velox_token_key)
        script.setAttribute('data-country', 'US')
        script.setAttribute('data-currency', 'USD')
        script.setAttribute('data-price', amount)
        
        if (script.readyState) {  // only required for IE <9
          script.onreadystatechange = () => {
            if ( script.readyState === "loaded" || script.readyState === "complete" ) {
              script.onreadystatechange = null
              console.log('reloading collect')
              this.loadCollect()
            }
          }
        } else {  //Others
          script.onload = () => {
            console.log('reloading collect')
            this.loadCollect()
          }
        }
        document.body.appendChild(script)
      }
      if (hasCFP && !hideAuxillaryPayments) {
        this.loadLendResults({ merchantId, cfpPartnerKey, phone, amount })
      } else this.setState({ lendResultLoading: false })
     
    } catch (err) {
      console.log('error: ', err.message)
      const { code, details: { message = '' } = {} } = JSON.parse(JSON.stringify(err))
      console.log('err: ', code, message)
      if (err.message === 'validation') {
        this.setState({ isSubmitting: false, invalid: true, invalidMessage: message })
      }
      this.setState({ isSubmitting: false, notInvalid: true })
    }
  }

  async loadLendResults({ merchantId, cfpPartnerKey, phone, amount }) {
    const {
      refId,
    } = this.state
    try {
      if (phone.length === 10) {
        this.setState({ lendResultLoading: true })
        this.setState({ isSynchrony: false })
        const functions = getFunctions(app)
        const loadLendResults = httpsCallable(functions, 'onCall-loadLendResults')
        const result = await loadLendResults({ merchantId, cfpPartnerKey, phone, amount, refId })
        console.log('loadLendResults: ', JSON.stringify(result))
        const { 
          lendResult,
          lendApplyUrl,
        } = result.data
        console.log('loadLendResults: ', lendResult, ' ', lendApplyUrl)
        // if (!lendResult || lendResult=='No Financing Options Found' || lendResult=='No Payment Plans Available') this.setState({ hasCFP: false })
        this.setState({ lendResult, lendApplyUrl })
        if (lendResult && (lendResult.includes('Synchrony') || lendResult.includes('Car Care')) ) {
          console.log('isSynchrony: true', lendResult.includes('Synchrony'),lendResult.includes('Car Care') )
          this.setState({ isSynchrony: true })
        }
        this.setState({ lendResultLoading: false })
        
      } else {
        this.setState({ lendResult: '', lendApplyUrl: '' })
        this.setState({ lendResultLoading: false })
      }
    } catch (err) {
      console.log('error: ', err.message)
      this.setState({ isSubmitting: false, notInvalid: true })
      this.setState({ lendResultLoading: false })
    }
  }

  reloadCollectJS(isACH) {

    // console.log('reloadCollectJS: ', this.state.velox_token_key, this.state.amount)
    var collectScript = document.querySelector("script[src='https://velox.transactiongateway.com/token/Collect.js']")
    if (collectScript) {
      console.log('removed collect script')
      collectScript.remove()
    }

        const script = document.createElement("script")
        script.src = "https://velox.transactiongateway.com/token/Collect.js"
        script.async = false
        script.setAttribute('data-tokenization-key', this.state.velox_token_key)
        script.setAttribute('data-country', 'US')
        script.setAttribute('data-currency', 'USD')
        script.setAttribute('data-price', this.state.amount)
        
        if (script.readyState) {  // only required for IE <9
          script.onreadystatechange = () => {
            if ( script.readyState === "loaded" || script.readyState === "complete" ) {
              script.onreadystatechange = null
              console.log('reloading collect')
              if (isACH) this.loadACHCollect()
              else this.loadCollect()
            }
          }
        } else {  //Others
          script.onload = () => {
            console.log('reloading collect')
            if (isACH) this.loadACHCollect()
            else this.loadCollect()
          }
        }
        document.body.appendChild(script)
        console.log('reloaded collect js')

  }
  unloadScript(scriptUrl) {
    // Find the script element with the specified URL
    const script = Array.from(document.getElementsByTagName('script')).find(s => s.src === scriptUrl)
    // console.log('script: ', script)
    //window.CollectJS.remove()
    // If the script element exists, remove it from the head element
    if (script) {
      console.log('removed collect script')
      script.remove()
    }
    
  }
  loadACHCollect() {
    console.log('loadACHCollect')
    
    window.CollectJS.configure({
      variant: 'inline',
      // styleSniffer: true,
      validationCallback: (field, valid, message) => {
        // console.log('ACH validationCallback: ', field, ' ', valid, ' ', message)
        this.fieldValidation({ field, valid, message })
      },
      fieldsAvailableCallback: () => {
        console.log('ach fields loaded')
        this.fieldsAvailable()
      },
      timeoutCallback: () => {
        console.log('timeoutCallback: ')
        this.submitError(`The tokenization didn't respond in the expected timeframe.  This could be due to an invalid or incomplete field or poor connectivity`)
      },
      callback: (token) => {
        // console.log('token: ',token)
        this.finishSubmit(token)
      },
      fields: {
        ccnumber: {
          placeholder: 'Card Number',
          selector: '#ccnumber'
        },
        ccexp: {
          placeholder: 'Expiration Date (MM/YY)',
          selector: '#ccexp'
        },
        cvv: {
          placeholder: 'CVV',
          selector: '#cvv'
        },
        checkaccount: {
          placeholder: 'Account Number',
          title: 'Account Number',
          selector: '#checkaccount'
        },
        checkaba: {
          placeholder: 'Routing Number',
          title: 'Routing Number',
          selector: '#checkaba'
        },
        checkname: {
          placeholder: 'Name on Checking Account',
          title: 'Name on Checking Account',
          selector: '#checkname'
        },
        
      },
      "customCss": {
        "display": "block",
        "width": "100%",
        "padding": "0.375rem 0.75rem",
        "font-size": '1rem',
        "font-weight":'400',
        "line-height": "24px",
        "height": "38px",
        "color": "#212529",
        "background-color": "#fff",
        "background-clip": "padding-box",
        "border": "1px solid #ced4da",
        "-webkit-appearance": "none",
        "appearance": "none",
        "border-radius": "0.25rem",
        "transition": "border-color .15s ease-in-out,box-shadow .15s ease-in-out",
        "text-transform": "none"
      }
    })
    console.log('loaded ACH collect')
  }
  loadCollect() {
    console.log('in loadCollect')
    
    window.CollectJS.configure({
        variant: 'inline',
        // styleSniffer: true,
        validationCallback: (field, valid, message) => {
          console.log('validationCallback: ', field, ' ', valid, ' ', message)
          this.fieldValidation({ field, valid, message })
        },
        fieldsAvailableCallback: () => {
          console.log('field loaded')
          this.fieldsAvailable()
        },
        timeoutCallback: () => {
          console.log('timeoutCallback: ')
          this.submitError(`The tokenization didn't respond in the expected timeframe.  This could be due to an invalid or incomplete field or poor connectivity`)
        },
        callback: (token) => {
          console.log('token: ',token)
          this.finishSubmit(token)
        },
        fields: {
          ccnumber: {
            placeholder: 'Card Number',
            selector: '#ccnumber'
          },
          ccexp: {
            placeholder: 'Expiration Date (MM/YY)',
            selector: '#ccexp'
          },
          cvv: {
            placeholder: 'CVV',
            selector: '#cvv'
          },
          
          applePay: {
            style: {
              'button-style': 'white-outline',
              height: '40px',
            },
            requiredBillingContactFields: [
              'postalAddress',
              'name'
            ],
            type: 'check-out'
          },
          googlePay: {
            billingAddressRequired: true,
            billingAddressParameters: {
              format: 'FULL'
            },
            buttonType: 'checkout',
          }
        },
        "customCss": {
          "display": "block",
          "width": "100%",
          "padding": "0.375rem 0.75rem",
          "font-size": '1rem',
          "font-weight":'400',
          "line-height": "24px",
          "height": "38px",
          "color": "#212529",
          "background-color": "#fff",
          "background-clip": "padding-box",
          "border": "1px solid #ced4da",
          "-webkit-appearance": "none",
          "appearance": "none",
          "border-radius": "0.25rem",
          "transition": "border-color .15s ease-in-out,box-shadow .15s ease-in-out",
          "text-transform": "none"
        }
    })
  }

  finishSubmit(response) {
    // console.log('finish submit: ', JSON.stringify(response))
    const {
      token,
      tokenType,
      card: { exp, number, type } = {},
      wallet: { billingInfo : { address1, address2, firstName, lastName, postalCode, city, state, country, phone } = {} } = {}
    } = response

    if (tokenType === 'googlePay' || tokenType === 'applePay') {
      this.setState( {
        firstName,
        lastName,
        address: `${address1}${address2 ? ' ' + address2 : ''}`,
        city,
        state,
        zip: postalCode,
        country,
        phone,
      })
    }
    this.completePayment({ token, exp, number, type, tokenType })

  }

  handleEmail({ canvas, email }) {
    const functions = getFunctions(app)
    const emailReceipt = httpsCallable(functions, 'onCall-emailReceipt')
    this.setState({ isSubmitting: true })
    const {
      merchantId,
      terminalId,
      accountName,
      datetime,
      expire,
      refId,
      signature,
      amount,
      orderId,
      invoiceRef,
    } = this.state
    // console.log(merchantId, terminalId, amount, datetime, expire, refId, signature, orderId)
    emailReceipt({ merchantId, terminalId, accountName, datetime, expire, refId, signature, amount, emailTo: email, file: canvas, orderId, invoiceRef }).then(result => {
      const {
        status
      } = result.data
      console.log(status)
      if (status ===  'Success') {
        // this.setState({ isSubmitting: false, alertMessage: status, complete: true, alertClass: 'alert alert-success alert-fixed' })
        window.location.reload(false)
      } else {
        this.setState({ isSubmitting: false, alertMessage: status, alertClass: 'alert alert-danger alert-fixed' })
      }
      // 
    }).catch(err => {
      console.log('error: ', err.message)
      this.setState({ isSubmitting: false, alertMessage: err.message, alertClass: 'alert alert-danger alert-fixed' })
    })
  }

  payPalError(error, debug_id, type, url) {
    // console.log('payPalError for debug_id',debug_id, error, type, url )
    const functions = getFunctions(app)
    const payPalError = httpsCallable(functions, 'onCall-payPalError')
    const {
      merchantId,
    } = this.state
    
    payPalError({ merchantId, error, debug_id, type, url }).then(() => {
      // console.log('payPalError result: ' + JSON.stringify(result))
    }).catch(err => {
      console.log('payPalError: ', err.message)
      this.setState({ isSubmitting: false, alertMessage: err.message, alertClass: 'alert alert-danger alert-fixed' })
    })
  }

  completePayPalPayment(request, response) {
    const {
      paymentSource,
    } = this.state
    const type = paymentSource
    console.log('completePayPalPayment for type',type )
    const functions = getFunctions(app)
    const makePayPalPayment = httpsCallable(functions, 'onCall-makePayPalPayment')

    const {
      merchantId,
      terminalId,
      datetime,
      expire,
      refId,
      signature,
      amount,
      firstName,
      lastName,
      address,
      city,
      state,
      zip,
      email,
      orderId,
      recaptcha,
      capture_signature,
      phone,
      authCode,
    } = this.state
    
    makePayPalPayment({ recaptcha, capture_signature, merchantId, terminalId, datetime, expire, refId,
      signature, amount, firstName, lastName, address, city, state, zip, type, orderId, authCode, phone, request, response }).then(result => {
      const {
        status,
        authCode,
        amountPaid,
        process,
        paymentType,
        cardType,
        isInternal,
        last4,
        avsResponse,
      } = result.data

      console.log('makePayPalPayment result.data: ', JSON.stringify(result.data))
      console.log('isInternal: ', isInternal)
      if (isInternal) {
        if (status ===  'Success') {
          this.setState({ 
            isComplete: true,
            alertMessage: null, 
            alertClass: null, 
            completeMessage: 'Approved',
            isSubmitting: false,
            receipt: false,
          })
        } else {
          this.setState({ isSubmitting: false, alertMessage: status, alertClass: 'alert alert-danger alert-fixed' })
        }
        console.log('posting message: ')
        window.top.postMessage(JSON.stringify({
          error: (status !==  'Success') ? true : false,
          message: {
            refId,
            status,
            orderId,
            firstName,
            lastName,
            address,
            city,
            state,
            zip,
            phone,
            email,
            authCode,
            amountPaid,
            process,
            paymentType,
            last4,
            cardType,
            avsResponse,
          }
        }),
          '*'
        )
      } else {
        if (status ===  'Success') {
          this.setState({ 
            isSubmitting: false, 
            authCode, 
            type, 
            alertMessage: null, 
            amountPaid, 
            process,
            paymentType,
            cardType,
            receipt: true, 
            alertClass: null 
          })
        } else {
          this.setState({ isSubmitting: false, alertMessage: status, alertClass: 'alert alert-danger alert-fixed' })
        }
      }
      // window.location.reload(false)
    }).catch(err => {
      console.log('posting error message ')
      window.top.postMessage(JSON.stringify({
        error: true,
        message: {
          status: err.message,
        }
      }),
        '*'
      )
      console.log('error: ', err.message)
      this.setState({ isSubmitting: false, alertMessage: err.message, alertClass: 'alert alert-danger alert-fixed' })
    })
  }
  handleCancelAlert() {
    console.log('cancel alert')
    this.setState({ alertMessage: undefined, alertClass: undefined })
  }

  async completePayment({ token, exp, number, type, tokenType }) {
    const functions = getFunctions(app)
    const makePayment = httpsCallable(functions, 'onCall-makePayment')

    const {
      merchantId,
      terminalId,
      datetime,
      expire,
      refId,
      signature,
      amount,
      firstName,
      lastName,
      address,
      city,
      state,
      zip,
      orderId,
      recaptcha,
      capture_signature,
      phone,
      email,
      payWithACH,
    } = this.state
    try {
      if (payWithACH) { 
        type = 'ach'
      }
      const result = await makePayment({ recaptcha, capture_signature, tokenType, merchantId, terminalId, datetime, expire, refId, signature, amount, firstName, lastName, address, city, state, zip, token, exp, number, type, orderId, phone })
      const {
        status,
        authCode,
        amountPaid,
        process,
        paymentType,
        last4,
        cardType,
        avsResponse,
        isInternal,
      } = result.data
      // console.log('result.data: ', JSON.stringify(result.data))

      if (isInternal) {
        if (status ===  'Success') {
          this.setState({ 
            isComplete: true,
            alertMessage: null, 
            alertClass: null, 
            completeMessage: 'Approved',
            isSubmitting: false,
            receipt: false,
          })
        } else {
          this.setState({ isSubmitting: false, alertMessage: status, alertClass: 'alert alert-danger alert-fixed' })
        }
        window.top.postMessage(JSON.stringify({
          error: (status !==  'Success') ? true : false,
          message: {
            refId,
            status,
            orderId,
            firstName,
            lastName,
            address,
            city,
            state,
            zip,
            phone,
            email,
            authCode,
            amountPaid,
            process,
            paymentType,
            last4,
            cardType,
            avsResponse,
          }
        }),
          '*'
        )
      } else {
        if (status ===  'Success') {
          this.setState({ 
            isSubmitting: false, 
            authCode, 
            number,
            type, 
            alertMessage: null, 
            amountPaid, 
            process,
            paymentType,
            last4,
            cardType,
            avsResponse,
            receipt: true, 
            alertClass: null,
          })
        } else {
          this.setState({ isSubmitting: false, alertMessage: status, alertClass: 'alert alert-danger alert-fixed' })
        }
      }
      // window.location.reload(false)
    } catch(err) {
      console.log('error: ', err.message)
      window.top.postMessage(JSON.stringify({
        error: true,
        message: {
          status: err.message,
        }
      }),
        '*'
      )
      const { code, details: { message = '' } = {} } = JSON.parse(JSON.stringify(err))
      console.log('err: ', code, message)
      if (err.message === 'ECONNRESET') {
        this.setState({ isSubmitting: false, invalid: true, invalidMessage: message })
      } else {
        this.setState({ isSubmitting: false, invalid: true, invalidMessage: err.message })
      }
    }
  }

  fieldValidation({ field, valid, message }) {
    this.setState((state) => {
      const fields = state.fields
      fields[field] = {
        valid,
        message,
      }
      return {
        fields
      }
    })
    
    console.log('this.state.payWithACH', this.state.payWithACH)

    if (this.state.subbmitting && !valid) {
      if (this.state.payWithACH && (field=='checkaba'|| field=='checkaccount'|| field=='checkname')) {
        this.setState({ isSubmitting: false, alertMessage: `${field} ${message}`, alertClass: 'alert alert-danger alert-fixed' })
      }
      if (!this.state.payWithACH && (field=='ccnumber'|| field=='ccexp'|| field=='cvv')) {
        this.setState({ isSubmitting: false, alertMessage: `${field} ${message}`, alertClass: 'alert alert-danger alert-fixed' })
      }
    } else if (!valid) {
      if (this.state.payWithACH && (field=='checkaba'|| field=='checkaccount'|| field=='checkname')) {
        this.setState({ alertMessage: `${field} ${message}`, alertClass: 'alert alert-danger alert-fixed' })
      }
      if (!this.state.payWithACH && (field=='ccnumber'|| field=='ccexp'|| field=='cvv')) {
        this.setState({ alertMessage: `${field} ${message}`, alertClass: 'alert alert-danger alert-fixed' })
      }
      
    }
    this.updateSubmitDisabled()
  }

  fieldsAvailable() {
    this.setState({ fieldsReady: true })
  }

  submitError(message) {
    console.log('submitError', message)
    this.setState({ isSubmitting: false, alertMessage: `${message}`, alertClass: 'alert alert-danger alert-fixed' })
  }

  handleClear() {
    this.signaturePadRef && this.signaturePadRef.current && this.signaturePadRef.current.clear()
    this.updateSubmitDisabled()
  }

  handleGetSignature() {
    if (!this.signaturePadRef.current.isEmpty()) {
      const data = this.signaturePadRef.current.toDataURL()
      // console.log('data: ', data)
      this.setState({ capture_signature: data })
      this.toggleSignature()
      this.updateSubmitDisabled()
    }
  }

  updateSubmitDisabled() {
    this.setState((state) => {
      // const hasSignature = !!state.capture_signature 
      // const hasRecaptcha = !!state.recaptcha 
      const fieldsValid = Object.keys(state.fields).reduce((retBol, field) => {
        // console.log('field: ' + field + ' - ' + JSON.stringify(state.fields[field]))
        // console.log('retBol - ach?:', retBol, this.state.payWithACH)
        if (this.state.payWithACH && (field=='checkaba'|| field=='checkaccount'|| field=='checkname')) {
          return retBol && (state.fields[field].valid || false)
        } else if (!this.state.payWithACH && (field=='ccnumber'|| field=='ccexp'|| field=='cvv')){
          return  retBol && (state.fields[field].valid || false)
        } else return retBol
        
      }, true)
      // console.log('fieldsValid: ', !fieldsValid)
      // console.log('hasRecaptcha: ', !hasRecaptcha)
      // console.log('hasSignature: ', !hasSignature)
      return {
        //submitDisabled: !fieldsValid || !hasRecaptcha || !hasSignature
        submitDisabled: !fieldsValid 
      }
    })
  }
  onPhoneChange(event) {
    console.log("onPhoneChange value:", event.target.value, this.state.phone)
    // console.log("onPhoneChange merchantId:", this.state.merchantId)
    
    this.setState({ phone: event.target.value })
    // console.log("calling loadLendResults", event.target.value)
    if (!this.state.hideAuxillaryPayments) this.loadLendResults({ merchantId: this.state.merchantId, cfpPartnerKey: this.state.cfpPartnerKey, phone: event.target.value, amount: this.state.amount })
  }
  onChange(value) {
    // console.log("Captcha value:", value)
    this.setState({ recaptcha: value })
    this.updateSubmitDisabled()
  }

  handleSubmit(event) {
    event.preventDefault()
    // console.log('handleSubmit: ' + this.state.showCaptchaSignature)
    const hasSignature = !!this.state.capture_signature 
    const hasRecaptcha = !!this.state.recaptcha 
    console.log('hasSignature: ' + hasSignature)
    console.log('hasRecaptcha: ' + hasRecaptcha)
    const {
      firstName,
      lastName,
      address,
      city,
      state,
      zip
    } = this.state
    if (!firstName) {
      return this.submitError(`Billing First Name is required`)
    }
    if (!lastName) {
      return this.submitError(`Billing Last Name is required`)
    }
    if (!address) {
      return this.submitError(`Billing Street Address is required`)
    }
    if (!city) {
      return this.submitError(`Billing City is required`)
    }
    if (!state) {
      return this.submitError(`Billing State/Province is required`)
    }
    if (!zip) {
      return this.submitError(`Billing Zip/Postal Code is required`)
    }
    if (this.state.isInternal) {
      this.setState({ isSubmitting: true })
      console.log('starting payment request')
      window.CollectJS.startPaymentRequest()
    } else {
      if (!this.state.showCaptchaSignature) {
        this.setState({ showCaptchaSignature: true })
      } else if (!hasSignature) {
        return this.submitError(`Signature is required`)
      } else if (!hasRecaptcha) {
        return this.submitError(`Recaptcha is required`)
      } else if (hasSignature && hasRecaptcha) {
        this.setState({ isSubmitting: true })
        console.log('starting payment request')
        window.CollectJS.startPaymentRequest()
      }
    }
    
  }
  toggleACH() {
    console.log('toggleACH')
    //this.unloadScript('https://velox.transactiongateway.com/token/Collect.js')
    //window.CollectJS.closePaymentRequest()

    //disable button
    this.setState((state) => {
      const fieldsValid = Object.keys(state.fields).reduce((retBol, field) => {
        console.log('toggle field', field,retBol )
        state.fields[field].valid = false
        return false
      }, true)
      console.log('fieldsValid: ', fieldsValid)
      return {
        submitDisabled: !fieldsValid 
      }
    })

    
    this.updateSubmitDisabled()
    console.log('toggleACH state', this.state.payWithACH)
    if (this.state.payWithACH) { 
      //set ach fields to null
      this.setState({ payWithACH: false }) 
      this.reloadCollectJS(false)
      //this.loadCollect()
    } else { 
      console.log('loading ACHCollect')
      //set cc fields to null
      var ccnum = document.getElementById('ccnumber')
      console.log('ccnumber: ', ccnum)
      ccnum.remove()
      this.setState({ payWithACH: true })
      this.reloadCollectJS(true)
      //this.loadACHCollect()
    }
    
  }
  toggleSignature() {
    this.setState((state) => { 
      return { signatureHidden: !state.signatureHidden }
    })
  }
  dBuyStarted = () => {
    console.log('dBuyStarted')
    this.setState({ dBuyStarted : true })
  }
  dBuyFinished = async (data, fullPromos) => {
    console.log('*** dBuyFinished', JSON.stringify(data))
    console.log('*** dBuyFinished', JSON.stringify(fullPromos))

    this.setState({ dBuyClosed: true })
    this.setState({  alertMessage: '' })
    const functions = getFunctions(app)
    const lookupSynchTxn = httpsCallable(functions, 'onCall-lookupSynchTxn')
    const lookupresp = await lookupSynchTxn(data)
    console.log('*** lookupSynchTxn', JSON.stringify(lookupresp))

    if (lookupresp && lookupresp.data.txnResponse && lookupresp.data.txnResponse.creditAuthorizationInfo 
      && lookupresp.data.txnResponse.creditAuthorizationInfo.transactionCode) {
      if (lookupresp.data.txnResponse.creditAuthorizationInfo.transactionCode==='033') {
        //finalize txn
        this.setState({ 
          dBuyNeedsFinal : true
        })

        const finalizeSynchTxn = httpsCallable(functions, 'onCall-finalizeSynchTxn')
        const resp = await finalizeSynchTxn(data)
        console.log('*** finalizeSynchTxn', JSON.stringify(resp))

        if (resp.data.authCode) {
          //set promo codes for receipt page
          let promoDescr = ''
          if (resp.data.synchronyPromoCode) promoDescr = fullPromos.get(resp.data.synchronyPromoCode)

          this.setState({ 
            synchronyPromoCode: resp.data.synchronyPromoCode,
            synchronyPromoDescr: promoDescr,
          })

          this.setState({ 
            authCode: resp.data.authCode,
            isSubmitting: false, 
            type: 'CFP', 
            alertMessage: null, 
            amountPaid: this.state.amount, 
            process: 't2p',
            paymentType: 'Sale',
            cardType: 'Synchrony',
            receipt: true, 
            alertClass: null,
          })
          return
        } else {
          // show Delinced message
          if (resp.data.error && resp.data.error.includes('Declined')) {
            this.setState({ isSubmitting: false, alertMessage: `${resp.data.error}`, alertClass: 'alert alert-danger alert-fixed' })
          }
          // show CALL FOR AUTH message
          if (resp.data.error && resp.data.error.includes('CALL FOR')) {
            this.setState({ isSubmitting: false, alertMessage: `${resp.data.error}`, alertClass: 'alert alert-danger alert-fixed' })
          }
        }
        //finalize txn
        this.setState({ 
          dBuyNeedsFinal : false
        })

      } else {
        // do nothing, cant show the response it might be address verification of customer just closed window
        
      }
    } else {
      // this.setState({ isSubmitting: false, alertMessage: 'Could not lookup transaction for id ' + data.txnToken, alertClass: 'alert alert-danger alert-fixed' })
      
    }
    //need to get another token for DBuy in case they start again
    this.setState({ isSynchrony: false })
    this.setState({ isSynchrony: true })
    this.setState({ dBuyClosed: false })
    

  }
  finishPayPal = (code, paymentSource, debug_id, url, payer_email, request, response) => {
    console.log('in finishPayPal: '+ code + ' ' + paymentSource)
    if (code.startsWith('Error')) {
      this.payPalError( code, debug_id, 'PayPal', url  )
      this.submitError(code)
    } else {
      console.log('success paypal: ' + code)
      this.setState({ payer_email : payer_email })
      this.setState({ authCode : code })
      this.setState({ paymentSource : paymentSource })
      this.setState({ palPalComplete : true })
      this.setState({ receipt : true })
      this.completePayPalPayment( request, response )
    }
  }

  render() {
    
    if (this.state.receipt) { // eslint-disable-line no-constant-condition
      return (
        <div>
          { this.state.alertMessage && (
            <div className={this.state.alertClass} role="alert" onClick={this.handleCancelAlert}>
              {this.state.alertMessage}
            </div>
          )}
          { this.state.isSubmitting  && (
            <div id="overlay">
              <div className="spinner"></div>
            </div>
          )}

          { !this.state.palPalComplete && (
          <PaymentReceipt {...this.state} handleEmail={this.handleEmail}/>
          )}

          { this.state.palPalComplete && (
            <PayPalPaymentReceipt {...this.state} handleEmail={this.handleEmail}/>
          )}

          
          
        </div>
      )
    }
    if (this.state.invalid) {
      return (
        <div className="App">
          <div className="container-fluid">
            <div className="row justify-content-center m-4">
            <div className="card">
              <div className="card-body">
                <h1>{this.state.invalidMessage}</h1>
              </div>
              </div>
            </div>
          </div>
        </div>
      )
    }
    if (this.state.isComplete) {
      return (
        <div className="App">
          <div className="container-fluid">
            <div className="row justify-content-center m-4">
            <div className="card">
              <div className="card-body">
                <h1>{this.state.completeMessage}</h1>
              </div>
              </div>
            </div>
          </div>
        </div>
      )
    }
    if (this.state.isCanceled) {
      return (
        <div className="App">
          <div className="container-fluid">
            <div className="row justify-content-center m-4">
            <div className="card">
              <div className="card-body">
                <h1>This link has been Canceled</h1>
              </div>
              </div>
            </div>
          </div>
        </div>
      )
    }
    if (this.state.notInvalid) {
      return (
        <div className="App">
          <div className="container-fluid">
            <div className="row justify-content-center m-4">
            <div className="card">
              <div className="card-body">
                <h1>This link is not valid</h1>
              </div>
              </div>
            </div>
          </div>
        </div>
      )
    }
    if (!this.state.merchantId) {
      return (
        <div className="App">
          { this.state.alertMessage ? (
          <div className={this.state.alertClass} role="alert">
            {this.state.alertMessage}
          </div>
        ) : (
          <div className="text-center">
            <div className="spinner-border m-5" style={{ width: 5 + 'rem', height: 5 + 'rem' }} role="status">
            </div>
          </div>
        )}
        </div>
      )
    }
    const {
      isInternal,
      hideHeader,
    } = this.state
    return (
      <div className="container-fluid">
        <div className="row justify-content-center">
          <div className="card bg-light mt-2" style={{ width: "35rem" }}>
            { this.state.isSubmitting && (
              <div id="overlay">
                <div className="spinner"></div>
              </div>
            )}

            { !isInternal && !hideHeader && this.state.accountImage && (
              <div className="row justify-content-center">
                <div className="col-10 mt-2">
                  <img src={this.state.accountImage} alt="image" className="rounded img-fluid border"/>
                </div>
              </div>
            )}
            { this.state.alertMessage && (
              <div className={this.state.alertClass} role="alert">
                {this.state.alertMessage}
              </div>
            )}
            { !isInternal && !hideHeader && <h3>{this.state.accountName}</h3>}
            { !isInternal && !hideHeader && <h5>{this.state.accountStreet} {this.state.accountCity}, {this.state.accountState} {this.state.accountZip}</h5>}
            { !isInternal && !hideHeader && <h5>{this.state.accountPhone}</h5>}
            { this.state.worldnetUrl ? '' : <h3>Total: ${this.state.amount}</h3>}
            { this.state.showOrderId && <h5>Order Number: {this.state.orderId}</h5>}
            { this.state.invoiceUrl ? (<a target="_blank" href={this.state.invoiceUrl}>Invoice</a>) : ''}
            { this.state.internalNotes && <div className="mt-1"><p>{this.state.internalNotes}</p></div>}
            { this.state.worldnetUrl ? (
              <div className="container-fluid">
                <div className="row justify-content-center">
                  <iframe width="534" height="600" src={this.state.worldnetUrl}></iframe>
                </div>
                {  (this.state.hasPayPal || this.state.hasVenmo) && !this.state.hideAuxillaryPayments && this.state.payPalMerchantStatus && this.state.payPalMerchantStatus==='MERCHANT_ACTIVATED' && !this.state.authOnly && (
                    <div>
                      <hr style={{ height: '10px', visibility: 'hidden' }} />
                      <PayPal props={this.state} finishPayPal={this.finishPayPal} 
                        payPalId={this.state.payPalId} service={this.state.payPalService} platformfee={this.state.payPalPlatformFee} 
                        hidePayLater={this.state.Hide_Paypal_Pay_Later}/> 
                    
                    </div>
                  )}

                  { this.state.lendResultLoading && (
                    <div className="cfpspinner">Loading Lend Results</div>
                  )}

                  { !this.state.lendResultLoading && !this.state.isSynchrony && this.state.lendResult && (
                    <div class="box">
                    <b>
                      <h7 >
                        <div style={{ color: 'white' }} dangerouslySetInnerHTML={{ __html: this.state.lendResult }} />
                        </h7>  
                      </b>
                    </div>   
                  )}

                  { !this.state.lendResultLoading && this.state.lendApplyUrl && !this.state.isSynchrony && (
                    <div class="box">
                    <b>
                      <h7 >
                        <span style={{ color: 'white' }}>&nbsp;&nbsp; Click <a class="mylink" target="_blank" href={this.state.lendApplyUrl}>here</a> to apply!
                        </span>   

                        </h7>  
                      </b>
                    </div>   
                  )}

                  
                    
                  { !this.state.lendResultLoading && this.state.lendResult && this.state.isSynchrony && (
                    <div class="inline">
                      <SynchDbuy key={this.state.synchkey} dBuyStarted={this.dBuyStarted} dBuyFinished={this.dBuyFinished} props={this.state} />
                    </div>   
                  )}
              </div>
            ) : (
              <form onSubmit={this.handleSubmit}>
                <div className="myborder mb-1">
                  <fieldset>
                    <div className="row mt-1 g-0">
                      <div className="col">
                        <input
                          type="text"
                          name="firstName"
                          className="form-control"
                          placeholder="First name"
                          onChange={event => this.setState({ firstName: event.target.value })}
                          value={this.state.firstName }
                        />
                      </div>
                      <div className="col ms-1">
                        <input
                          type="text"
                          name="lastName"
                          className="form-control"
                          placeholder="Last name"
                          onChange={event => this.setState({ lastName: event.target.value })}
                          value={this.state.lastName}
                          size="30"
                        />
                      </div>
                    </div>
                    <div className="mt-1"> 
                      <input
                        type="text"
                        name="phone"
                        className="form-control"
                        placeholder="Phone"
                        onChange={this.onPhoneChange}
                        value={this.state.phone}
                        size="30"
                      />
                    </div>
                    <div className="mt-1"> 
                      <input
                        type="text"
                        name="address"
                        className="form-control"
                        placeholder="Street address"
                        onChange={event => this.setState({ address: event.target.value })}
                        value={this.state.address}
                        size="30"
                      />
                    </div>
                    <div className="row mt-1 g-0">
                    <div className="col"> 
                      <input
                        type="text"
                        name="city"
                        className="form-control"
                        placeholder="City"
                        onChange={event => this.setState({ city: event.target.value })}
                        value={this.state.city}
                        size="30"
                      />
                    </div>
                    <div className="col ms-1"> 
                      <input
                        type="text"
                        name="state"
                        className="form-control"
                        placeholder="State/Province"
                        onChange={event => this.setState({ state: event.target.value })}
                        value={this.state.state}
                        size="30"
                      />
                    </div>
                    </div>
                    <div className="mt-1"> 
                      <input
                        type="text"
                        name="zip"
                        className="form-control"
                        placeholder="Zip/Postal Code"
                        onChange={event => this.setState({ zip: event.target.value })}
                        value={this.state.zip}
                        size="30"
                      />
                    </div>
                    { this.state.dBuyStarted && this.state.dBuyNeedsFinal && (
                        <div class="synchoverlay" >
                          <div class="synchspinnertext" >Finalizing Synchrony Transaction<div className="cfpspinner"></div></div>
                        </div>
                      ) 
                    }
                    { this.state.dBuyStarted && this.state.dBuyClosed && !this.state.dBuyNeedsFinal && (
                        <div class="synchoverlay" >
                          <div class="synchspinnertext" >Checking results<div className="cfpspinner"></div></div>
                        </div>
                      ) 
                    }
                    <br/>
                    { this.state.authOnly ? (<h3>AUTH ONLY</h3>) : '' }

                    { !this.state.payWithACH && !this.state.disableCC ? (
                      <div>
                        <div></div>
                        
                        <br/>
                        Enter Credit Card Information
                        <div>
                          <div className="mt-1">
                              <div id="ccnumber"/>
                          </div>
                          <div className="row mt-1 g-0">
                            <div className="col">
                              <div id="ccexp"/>
                            </div>
                            <div className="col ms-1">
                              <div id="cvv"/>
                            </div>
                          </div>
                        </div>
                        <br/>
                      </div>
                    ) : <div/> }
                      

                    { this.state.achEnabled && this.state.payWithACH ? (
                      <div>
                      Enter Bank Information
                      <div>
                        <div className="mt-1">
                          <div id="checkaba"/>
                        </div>
                        <div className="mt-1">
                          <div id="checkaccount"/>
                        </div>
                        <div className="mt-1">
                          <div id="checkname"/>
                        </div>
                      </div>
                      </div>
                      ) : <div/>
                      }
                    
                    { this.state.achEnabled && !this.state.disableCC && !this.state.authOnly ? (
                      <div className="mt-1">
                        
                          <div>
                          <button type="button"
                            onClick={this.toggleACH}
                            className="btnwide btn btn-primary mt-1 mb-1"
                          >{ this.state.payWithACH ? ( 'Click To Use Credit Card' ) : 'Click To Use Bank Account'}</button>
                          
                          </div>
                        
                      </div>
                      ) : <div/>}
                  

                  </fieldset>
                  
                  {this.state.showCaptchaSignature && !this.state.disableCC && this.state.signatureHidden && !this.state.capture_signature ? ( 
                    <div className="mt-1"> 
                      <input
                        type="text"
                        name="signature"
                        className="form-control"
                        placeholder="Signature"
                        onClick={this.toggleSignature}
                      />
                    </div>
                  ) : this.state.showCaptchaSignature &&  this.state.signatureHidden && this.state.capture_signature ? (
                    <div className="form-control mt-1" onClick={this.toggleSignature}>
                      <img src={this.state.capture_signature} alt="image" className="img-fluid"/>
                    </div>
                  ) : this.state.showCaptchaSignature ? (
                    <div>
                      <div className="mt-1 form-control">
                        <SignaturePad ref={this.signaturePadRef}/>
                      </div>
                      <div className="mt-1 mb-1">
                        <button type="button" onClick={this.handleClear} className="btn btn-light">Clear</button>
                        <button type="button" onClick={this.handleGetSignature} className="btn btn-primary ms-1">Capture</button>
                      </div>
                    </div> 
                  ) : <div/> }
                    
                    { this.state.showCaptchaSignature ? (
                    <div className="mt-1 justify-content-center">
                      <ReCAPTCHA
                        ref={recaptchaRef}
                        sitekey="6LeajoYgAAAAAAipHXxCDX8k3zOIo8tJ4IDd-MQ6"
                        onChange={this.onChange}
                      />  
                    </div>
                    ) : <div/>}
                    { !this.state.hideAuxillaryPayments && this.state.payWithACH ? (
                        <button
                          type="submit"
                          className="btnwide btn btn-primary mt-1 mb-1"
                          disabled={this.state.isSubmitting || this.state.submitDisabled}
                        >Pay With ACH</button>
                      ) : 
                      <div/>
                    } 

                    { !this.state.payWithACH && !this.state.disableCC ? (
                      <button
                        type="submit"
                        className="btnwide btn btn-primary mt-1 mb-1"
                        disabled={this.state.isSubmitting || this.state.submitDisabled || this.state.disableCC}
                      >{ this.state.authOnly ? ( 'Authorize With Card' ) : 'Pay With Card'}</button>
                      ) : 
                      <div/>
                    } 

                    { !this.state.hideAuxillaryPayments && this.state.hasGooglePay && (
                      <div className="mb-1">
                        <div id="googlepaybutton"></div>
                      </div>
                    )}
                    {  !this.state.hideAuxillaryPayments && this.state.hasApplePay && window.ApplePaySession && (
                      <div className="mb-1">
                        <div id="applepaybutton"></div>
                      </div>
                    )}
                  </div>

                  
                  { (this.state.hasPayPal || this.state.hasVenmo)  && !this.state.hideAuxillaryPayments && this.state.payPalMerchantStatus && this.state.payPalMerchantStatus==='MERCHANT_ACTIVATED' && !this.state.authOnly && (
                    <div>
                      <hr style={{ height: '10px', visibility: 'hidden' }} />
                      <PayPal props={this.state} finishPayPal={this.finishPayPal} 
                        payPalId={this.state.payPalId} service={this.state.payPalService} platformfee={this.state.payPalPlatformFee} 
                        hidePayLater={this.state.Hide_Paypal_Pay_Later}/> 
                    
                    </div>
                  )}

                  { this.state.lendResultLoading && (
                    <div class="cfpspinnertext" >Loading Lend Results ... <div className="cfpspinner"></div></div>
                  )}

                  { !this.state.lendResultLoading  && !this.state.isSynchrony && this.state.lendResult && (
                    <div class="box">
                    <b>
                      <h7 >
                        <div style={{ color: 'white' }} dangerouslySetInnerHTML={{ __html: this.state.lendResult }} />
                        </h7>  
                      </b>
                    </div>   
                  )}

                  { !this.state.lendResultLoading &&  this.state.lendApplyUrl && !this.state.isSynchrony && (
                    <div class="box">
                    <b>
                      <h7 >
                        <span style={{ color: 'white' }}>&nbsp;&nbsp; Click <a class="mylink" target="_blank" href={this.state.lendApplyUrl}>here</a> to apply!
                        </span>   

                        </h7>  
                      </b>
                    </div>   
                  )}

                  

                  { !this.state.lendResultLoading && this.state.lendResult && this.state.isSynchrony &&  (
                    <div class="inline">
                      <SynchDbuy key={this.state.synchkey} dBuyStarted={this.dBuyStarted} dBuyFinished={this.dBuyFinished} props={this.state} />
                    </div>  
                  )}   
                </form>
              )}
            { this.state.disclosure ? <i><br/>{this.state.disclosure}</i> : ''}
          </div>
        </div>
      </div>
    )
  }
}

function PaymentReceipt(props) {
  const [captureEmail, setCaptureEmail] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [email, setEmail] = useState()
  const handleClick = () => {
    console.log('click')
    if (!captureEmail) {
      setCaptureEmail(true)
    } else if (captureEmail && email) {
      setIsSubmitting(true)
      html2canvas(document.getElementById('root')).then(function(canvas) {
        return props.handleEmail({ canvas: canvas.toDataURL(), email })
      })
    }
  }
  return (
    <div>
      <Receipt {...props} />
      <div className="col d-flex justify-content-center">
        <div className="row justify-content-center">
          { captureEmail && (
            <input
            type="email"
            name="email"
            className="form-control mt-1"
            placeholder="Email Address"
            onChange={event => setEmail(event.target.value)}
            data-html2canvas-ignore
            />
          )}
          <button
            type="submit"
            onClick={handleClick}
            className="btn btn-primary mt-1 mb-2"
            data-html2canvas-ignore
            disabled={isSubmitting}
          >
            Email Receipt
          </button>
        </div>
      </div>
    </div>
  )
}

//import venmo from '../images/Venmo3.png'
//{ this.state.hasVenmo && (
//  <img style={{ width: 100 + '%', height: 65 + 'px' }} src={venmo} alt="image" className="img-fluid rounded mt-1"/>
//)}
//import paypal from '../images/PayPal2.png'
import PayPal from '../components/PayPal2.js'
import PayPalReceipt from './paypalreceipt.js'

function PayPalPaymentReceipt(props) {
    const [captureEmail, setCaptureEmail] = useState(false)
    const [email, setEmail] = useState()
    const handleClick = () => {
      console.log('paypal receipt click')
      if (!captureEmail) {
        setCaptureEmail(true)
      } else if (captureEmail && email) {
        html2canvas(document.getElementById('root')).then(function(canvas) {
          props.handleEmail({ canvas: canvas.toDataURL(), email })
        })
      }
    }
    /* <PayPalReceipt amountPaid={propData.amount} authCode={authCode} /> */
    return (
      <div>
        <PayPalReceipt {...props}/>
        <div className="col d-flex justify-content-center">
          <div className="row justify-content-center">
            { captureEmail && (
              <input
              type="email"
              name="email"
              className="form-control mt-1"
              placeholder="Email Address"
              onChange={event => setEmail(event.target.value)}
              data-html2canvas-ignore
              />
            )}
            <button
              type="submit"
              onClick={handleClick}
              className="btn btn-primary mt-1 mb-2"
              data-html2canvas-ignore
            >
              Email Receipt
            </button>
          </div>
        </div>
      </div>
    )
  }
