import './App.css'
import { Routes, Route } from 'react-router-dom'
import Payment from './components/payment.js'
// import PaymentForm from './components/paymentForm.js'
// import Worldnet from './components/worldnet.js'
// import WorldnetSecure from './components/worldnetSecure.js'
import WorldnetComplete from './components/worldnetComplete'
import PaymentReceipt from './components/paymentReceipt.js'
import Terminal from './components/terminal.js'

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="pay" element={<Payment />} ></Route>
        {/* <Route path="payform" element={<PaymentForm />} ></Route> */}
        <Route path="rec" element={<PaymentReceipt />} ></Route>
        {/* <Route path="worldnet" element={<Worldnet />} ></Route> */}
        {/* <Route path="worldnetToken" element={<WorldnetSecure />} ></Route> */}
        <Route path="worldnetComplete" element={<WorldnetComplete />} ></Route>
        <Route path="term/:id" element={<Terminal />} ></Route>
        <Route
          path="*"
          element={
            <main style={{ padding: "1rem", fontSize: "2em" }}>
              <p>403</p>
            </main>
          }
        />
      </Routes>
    </div>
  )
}

export default App
